import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0036 from '../../../components/molecules/columnDetailInner0036'
import UsefulFunctionLayout023 from '../../../components/molecules/usefulFunctionLayout023'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0036 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '代休と振替休日の違いとは？休日出勤時にどちらを使うべき？',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="代休と振替休日の違いは？基本知識や注意しなければならないこと"
        description="休日労働をした際は、代休と振替休日を活用して休日を付与しますが、大きな違いは事前手続きの有無と割増賃金の発生です。違いをよく確認しておきましょう。"
        ogUrl="https://kintaicloud.jp/column/details/C0036/"
        ogType="article"
        ogTitle="代休と振替休日の違いは？基本知識や注意しなければならないこと"
        ogDescription="休日労働をした際は、代休と振替休日を活用して休日を付与しますが、大きな違いは事前手続きの有無と割増賃金の発生です。違いをよく確認しておきましょう。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0036.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0036 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout023 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事
         * TODO 田村申し送り事項
         * 記事NO35公開時にコメントアウトを取る
        <ColumnRelationCards0036 /> */}
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0036

export const pageQuery = graphql`
  query C0036 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
