import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0036 = () => (
  <Wrapper>
    <ColumnH1 label="代休と振替休日の違いは？基本知識や注意しなければならないこと" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.11.11</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        突発的な顧客対応など、労働者であればやむを得ず休日に労働せざるを得ないことも少なくありません。また、会社としても管理職のみで対応が困難な場合、休日であっても労働者の助力を必要とせざるを得ない場合もあるでしょう。
        <br />
        本来、労働基準法上では週に1日は休日を確保しなければなりません。そのような場合に
        <span>「代休」</span>と<span>「振替休日」</span>
        を活用して休日を付与するという選択肢があります。どちらを選択するかで法律上の義務が変わってきますが、誤った労務管理も散見されることから、代休と振替休日の違いについて確認していきましょう。
      </p>
      <img src="/images/column/details/c0036.jpg" alt="キンクラコラム画像36" />
      <h1>休日労働を命ずる前に</h1>
      <p>
        前提として無制限に休日労働を命じることはできず、休日労働を行わせる可能性がある場合、予め所轄労働基準監督署へ
        <span>36協定の届出</span>をしておかなければなりません。
        <br />
        36協定と言うと時間外労働を行わせるための手続きと認識されてるのが多い印象ですが、協定書の下段には休日労働を行わせる場合の頻度、始業および終業の時刻を明記し、届け出ておく必要があります。
      </p>
      <h1>代休とは</h1>
      <p>
        休日労働をさせた事後に代償措置として代わりの休日を与えることです。よって、代休を付与したとしても休日に労働させた事実は消えず、休日労働の事実は残っているということです。
      </p>
      <h1>振替休日とは</h1>
      <p>
        予め定めてある休日を<span>事前に手続き</span>
        し、他の労働日と交換することを指します。「本来は休日であった日に働いた日」は「通常の労働日」の扱いになることから
        <span>休日労働にはなりません</span>。
        <br />
        <br />
        振替休日の注意点は無制限に使えるということではなく、就業規則に振替休日の規定を設けておく必要があります。その際に以下の点を網羅しておくことが求められます。
        <br />
        1点目は<span>振替日を事前に指定する</span>
        ことです。言うまでもなく、事前に指定することが振替休日の要件を満たす前提条件となることから、事後に休日を指定する状態では振替休日の要件を満たしません。2点目として、振替日は
        <span>4週4日の法定休日を確保する</span>
        ように調整することです。3点目として、遅くとも
        <span>前日の勤務終了までに通知</span>をすることです。
      </p>
      <Introduction003 />
      <h1>双方の相違点とは</h1>
      <p>
        実務上最も大きな相違点は<span>割増賃金の発生</span>
        です。代休の場合、休日労働の事実は消えないため、休日労働に対する
        <span>割増賃金は必要</span>
        であるのに対し、振替休日の場合は予め本来の休日と労働日を交換することから
        <span>割増賃金は発生しません</span>。
        <br />
        しかし、例外なく発生しないということではありません。例えば、同一週内で振替休日を設定できず、
        <span>週単位での労働時間が40時間を超えた場合</span>
        は割増賃金の支払いが必要となります。反対に、同一週内で振替休日を設定すれば割増賃金は発生しないということです。
        <br />
        <br />
        具体例として、やむを得ず本来は休日である日曜日に出勤を命じ、予め翌々日の火曜日に休日を交換した場合、割増賃金は不要ということです。仮に火曜日だけでなくその週の全ての日に休日の設定ができず、翌週にずれ込んでしまった場合、結果として「法定労働時間」である週40時間を超えた場合、割増賃金の支払いが発生します。よって、振替休日の適正運用のポイントは
        <span>同一週内に振替休日を設定する</span>ということです。
        <br />
        <br />
        次に<span>「週の起算日」</span>
        はいつになるのかという問題が生じます。通達では特段、会社として何も定めていない場合には「日曜日」と解されます。当然、就業規則で「土曜日」と定めた場合は「土曜日」が週の起算日と解されます。給与計算においても週の起算日は重要な論点となることから、今一度自社の
        <span>「週の起算日」を明確化</span>しておくことは重要です。
      </p>
      <h1>付与できる期限</h1>
      <p>
        代休の場合、特段期限が制限されているわけではありませんが、賃金締め日を超えて翌月に代休取得した場合、休日出勤発生月には休日出勤手当を全額支給し、次月に代休取得日分を控除するというような複雑な処理が必要になります。
        <br />
        反対に振替休日の場合、原則として同一週内で振り替えることで割増賃金の支払いが回避でき、休日管理も容易になります。
        <br />
        <br />
        また、4週を通じて4日以上の休日を与える<span>「変形休日制」</span>
        を採用している場合、就業規則に変形休日制の起算日を定めておく必要がありますが、出勤する休日の属する4週の範囲内で設定することが求められます。
        <br />
        <br />
        しかし、法律問題以前に休日が確保できない勤務体制は健康問題に発展してしまうリスクもあることから
        <span>
          休日は可能な限り休日労働を命じた日と密接した日に設定しておく
        </span>
        ことが肝要です。
      </p>
      <h1>最後に</h1>
      <p>
        <span>
          事前の告知は代休の場合は不要ですが、振替休日の場合は必須条件となります。
        </span>
        <br />
        多くの企業で代休と振替休日の解釈が混在していることや、逆の解釈を採用している場合も散見されます。特に従業員数が多い企業の場合は誤った運用を継続している場合、影響範囲も広範囲に及ぶことから注意が必要です。
        <br />
        <br />
        また、労働条件の中でも極めて重要な給与にも影響が出てしまう部分であることから適正な運用ができているか今一度確認をしておきましょう。その際に社内のみで解決が難しい場合は専門家の助言を得る等、専門的かつ客観的な視点で精査することが肝要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0036
